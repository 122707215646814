<template>
  <div class="subpackage flex-column">
    <div class="nameTit flex-space-between">
      <div class="tp-left flex-center-v">
        <span>分包单位</span>
        <el-button type="primary" size="mini" plain style="margin-left: 20px" @click="showFormTank" v-if="isProjectAdmin||isAdmin">新增分包单位</el-button>
      </div>
      <div class="tp-right flex-center-v">
        <el-input v-model="searchData.name" placeholder="分包单位名称" clearable size="small" style="margin-right:15px;"></el-input>
        <el-button type="primary" size="small" style="width:80px;" @click="search">查询</el-button>
      </div>
    </div>
    <div class="cont flex-1-auto">
      <el-table ref="multipleTable" :data="tableList" height="100%" style="width: 100%" tooltip-effect="light myTooltips">
        <el-table-column type="index" label="序号" align="center"></el-table-column>
        <el-table-column prop="unitType" label="分包单位类型" align="center">
          <template slot-scope="{row}">
            <span>{{ row.unitType == 1 ? '专业分包' : '劳务分包' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="分包单位名称" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="siteHead" label="现场负责人" align="center"></el-table-column>
        <el-table-column prop="siteHeadMobile" label="现场负责人电话" align="center"></el-table-column>
<!--        <el-table-column prop="siteHeadMobile" label="创建时间" align="center"></el-table-column>-->
        <el-table-column label="状态" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.status ? '启用中' : '作废中' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" v-if="isProjectAdmin||isAdmin">
          <template slot-scope="{ row }">
            <el-button type="text" size="mini" @click="edit(row)">编辑</el-button>
            <el-button v-if="!row.status" type="text" size="mini" @click="enableOrDisable(row, 'star')">启用</el-button>
            <el-button v-else type="text" size="mini" @click="enableOrDisable(row, 'end')">作废</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flex-center" style="padding-top: 10px">
      <el-pagination
          v-if="total > searchData.pageSize"
          background
          :current-page.sync="searchData.pageCurrent"
          :page-size="searchData.pageSize"
          :total="total"
          layout="total, prev, pager, next"
          @current-change="handleSizeChangeData"
      ></el-pagination>
    </div>
    <el-dialog :title="title" :visible.sync="showTank" width="30%" :close-on-click-modal="false" @close="close">
      <el-form ref="form" :model="formData" :rules="rules" class="demo-form-inline" label-width="125px">
        <el-form-item label="分包单位类型" prop="unitType">
          <el-select v-model="formData.unitType" placeholder="请选择组织类型" style="width: 100%">
            <el-option label="专业分包" :value="1"></el-option>
            <el-option label="劳务分包" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分包单位名称" prop="name">
          <el-input v-model="formData.name" placeholder="请输入分包单位名称"></el-input>
        </el-form-item>
        <el-form-item v-if="false" label="现场负责人" prop="siteHead" >
          <div class="flex">
            <el-input v-model="formData.siteHead" disabled placeholder="请选择现场负责人"></el-input>
            <el-button class="form-choose-button" @click="startChoose" style="margin-left: 10px;">选择</el-button>
          </div>
        </el-form-item>
        <el-form-item label="现场负责人" prop="siteHead" >
          <el-input v-model="formData.siteHead" placeholder="请选择现场负责人"></el-input>
        </el-form-item>
        <el-form-item label="现场负责人电话" prop="siteHeadMobile">
          <el-input v-model="formData.siteHeadMobile" placeholder="现场负责人电话"></el-input>
        </el-form-item>
        <el-form-item label="班组名称" prop="groupName">
          <el-input type="textarea" placeholder="请输入班组名称" v-model="formData.groupName" :rows="4" maxlength="255" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="save">确 定</el-button>
        <el-button size="small" @click="showTank = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 选择现场负责人 -->
    <el-dialog ref="chooseRefs" title="选择现场负责人" :visible.sync="isShowChooseSafe" width="40%">
      <div class="content flex-column">
        <div class="cont">
          <el-table :data="safeList" :header-cell-style="{background:'#f5f5f5',color:'#333333'}">
            <el-table-column align="center" prop="nickname" label="项目安全员"></el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="{ row }">
                <el-button type="primary" size="mini" plain @click="chooseSafe(row)">选择</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import apis from './apis'
import {mapGetters} from "vuex";

export default {
  name: "subpackage",

  components: {},

  props: {},

  data() {
    return {
      title: '新增分包单位',
      showTank: false,
      formData: {},
      rules: {
        unitType: [{ required: true, message: '请选择分包单位类型', trigger: 'change' }],
        name: [{ required: true, message: '请输入分包单位名称', trigger: 'blur' }],
        siteHead: [{ required: true, message: '请输入现场负责人', trigger: ['blur', 'change'] }],
        siteHeadMobile: [{ required: true, message: '请输入现场负责人', trigger: ['blur', 'change'] }],
        groupName: [{ required: true, message: '请输入班组名称', trigger: 'blur' }]
      },
      tableList: [],
      searchData: {
        pageCurrent: 1,
        pageSize: 10
      },
      total: 0,
      safeList: [],
      isShowChooseSafe: false
    }
  },

  computed: {
    ...mapGetters(['companyId','optionItem']),
    isProjectAdmin() {
      return this.optionItem && this.optionItem.roleNameList && this.optionItem.roleNameList.includes("项目管理员");
    },
    isAdmin() {
      return this.optionItem && this.optionItem.roleNameList && this.optionItem.roleNameList.includes("超级管理员")
    },
  },

  watch: {
    companyId() {
      this.search()
    }
  },

  mounted() {
    if(this.companyId) this.getTableList()
  },

  methods: {
    async getTableList() {
      this.searchData.departmentCode = this.companyId
      const result = await apis.pageSubcontractUnit(this.searchData)
      this.tableList = result.list
      this.total = result.total
    },
    showFormTank() {
      this.title = '新增分包单位'
      this.formData.departmentCode = this.companyId
      this.getNotifY().async
      this.isEdit = false
      this.showTank = true
    },
    close() {
      this.formData = {}
      this.$refs.form.resetFields()
    },
    save() {
      this.$refs.form.validate(async validate => {
        if (validate) {
          if(this.isEdit) await apis.editSubcontractUnit(this.formData)
          else await apis.addSubcontractUnit(this.formData)
          this.$message.success('操作成功')
          this.showTank = false
          this.getTableList().async
        }
      })
    },
    async add() {
      this.title = '新增分包单位'
      this.isEdit = false
      this.showTank = true
    },
    async edit(data) {
      this.title = '编辑分包单位'
      this.formData = JSON.parse(JSON.stringify(data))
      this.getNotifY(data.departmentCode).async
      this.isEdit = true
      this.showTank = true
    },
    enableOrDisable(data, status) {
      const title = status == 'star' ? '是否启用该分包单位' : '是否作废该分包单位'
      this.$confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        if (status == 'star') await apis.enableSubcontractUnit(data.id)
        else await apis.disableSubcontractUnit(data.id)
        this.$message.success('操作成功')
        // this.init().async
        this.getTableList().async
      })
    },
    startChoose(){
      this.safeList = this.notifier
      this.isShowChooseSafe = true
    },
    async getNotifY(id){
      if (id){
        this.notifier = await apis.findByDepartmentId(id)
      }else{
        this.notifier = await apis.findByDepartmentId(this.companyId)
      }
    },
    chooseSafe(row){
      this.formData.siteHead = row.nickname
      this.formData.siteHeadMobile = row.username
      this.isShowChooseSafe = false
    },
    // 查询
    search() {
      this.searchData.pageCurrent = 1
      this.getTableList().async
    },
    // 切换页码
    async handleSizeChangeData(val) {
      this.searchData.pageCurrent = val
      this.getTableList().async
    }
  }
}
</script>

<style lang="stylus" scoped>
.subpackage {
  height 100%
  padding 10px
}
</style>
